import { AppShell } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { FooterSimple } from "./footer";
import { HeaderMiddle } from "./header";

const Layout = (props) => {
  const { mapRef } = props;
  return (
    <AppShell
      padding={0}
      header={<HeaderMiddle mapRef={mapRef} />}
      footer={<FooterSimple />}
    >
      <Outlet />
    </AppShell>
  );
};

export default Layout;
