import Base from "./base";
import Button from "./button/button";
// import iconPlus from '../icons/ts/plus';
// import iconMinus from '../icons/ts/minus';

// export type ResetControlOptions =
//     {
//         zoom: number
//         lng: number
//         lat: number
//     }

export default class ResetControl extends Base {
  // buttonReset: Button;
  // zoom: number;
  // lng: number;
  // lat: number;

  constructor(options) {
    super();
    this.buttonReset = new Button();
    this.zoom = options.zoom;
    this.lng = options.lng;
    this.lat = options.lat;
  }

  insert() {
    this.addClassName("mapbox-reset");
    this.buttonReset
      .setIcon(iconReset())
      .onClick(() =>
        this.map.flyTo({ center: [this.lng, this.lat], zoom: this.zoom })
      );
    this.addButton(this.buttonReset);
  }

  onAddControl() {
    // this.map.setCenter([0, 0])
    this.insert();
  }
}

const svg = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-reset" color="#333333" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
<path d="M21 21l-6 -6"></path>
<path d="M3.268 12.043a7.017 7.017 0 0 0 6.634 4.957a7.012 7.012 0 0 0 7.043 -6.131a7 7 0 0 0 -5.314 -7.672a7.021 7.021 0 0 0 -8.241 4.403"></path>
<path d="M3 4v4h4"></path>
</svg>`;

const iconReset = () =>
  new DOMParser().parseFromString(svg, "image/svg+xml").firstChild;
