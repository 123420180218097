import {
  createStyles,
  Header,
  Group,
  ActionIcon,
  Container,
  Anchor,
  Text,
  Center,
  rem,
  Badge,
  UnstyledButton,
  Modal,
  useMantineTheme,
  Button,
  Box,
  Stack,
  Flex,
  Drawer,
  Burger,
  MediaQuery,
} from "@mantine/core";
// import {
//   IconBrandTwitter,
//   IconBrandYoutube,
//   IconBrandInstagram,
//   IconBrandFacebook,
//   IconBrandLinkedin,
//   IconMail,
//   IconLogout,
// } from "@tabler/icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import ChamberLogo from "./chamber-logo";
import { SpotlightProvider } from "@mantine/spotlight";
import { SearchControl } from "./spotlightSearch";
import { useCallback, useEffect, useState } from "react";
import { useMemberStore } from "../stores/useMemberStore";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";

import {
  IconHelpCircle,
  IconSearch,
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconMail,
  IconLogout,
  IconExternalLink,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "flex-start",
    },
  },

  links: {
    width: 260,

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  social: {
    width: 260,

    [theme.fn.smallerThan("sm")]: {
      width: "auto",
      marginLeft: "auto",
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

export function HeaderMiddle(props) {
  const { mapRef } = props;
  const { user, logout } = useAuth();
  const { classes, cx } = useStyles();
  const location = useLocation();
  //const { members } = useMemberStore();
  const [members, setMembers] = useState();
  const [opened, { open, close }] = useDisclosure(false);
  const [mobileMenuOpened, { open: openMobileMenu, close: closeMobileMenu }] =
    useDisclosure(false);

  const theme = useMantineTheme();

  const [isFirstTime, setIsFirstTime] = useLocalStorage({
    key: "peccMapWelcome",
    defaultValue: true,
  });

  useEffect(() => {
    if (isFirstTime) {
      open();
    } else {
      close();
    }
  }, [isFirstTime]);

  const handleWelcomeClose = () => {
    close();
    setIsFirstTime(false);
  };

  const groupedMembers = useQuery({
    queryKey: ["groupedMembers"],
    queryFn: async () => {
      const response = await fetch("/server/controller-member/grouped");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  });

  const logMemberEvent = useMutation({
    mutationKey: ["logMemberEvent2"],
    mutationFn: async ({ event, member }) => {
      const response = await fetch(`/server/controller-member/event`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: event,
          member: member,
        }),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
  });

  useEffect(() => {
    setMembers(
      groupedMembers.data?.data?.flatMap((category) => {
        return category.members?.map((member) => {
          return { ...member };
        });
      })
    );
  }, [groupedMembers.data]);

  const rawSocials = [
    {
      icon: IconBrandInstagram,
      link: "https://www.instagram.com/pec_chamber/",
    },
    {
      icon: IconBrandFacebook,
      link: "https://www.facebook.com/PECChamber/",
    },
    {
      icon: IconBrandLinkedin,
      link: "https://www.linkedin.com/company/princeedwardcountychamberofcommerce/?viewAsMember=true",
    },
    {
      icon: IconBrandTwitter,
      link: "https://twitter.com/pec_chamber?lang=en",
    },
    {
      icon: IconBrandYoutube,
      link: "https://www.youtube.com/channel/UCZN9-gBplp5hsLlKdKTzhrA",
    },
    {
      icon: IconMail,
      link: "mailto:hello@pecchamber.com",
    },
  ];

  const socials = rawSocials.map((social, index) => (
    <ActionIcon
      key={`socialIcon-${index}`}
      size="lg"
      component="a"
      href={social.link}
      target="_blank"
    >
      <social.icon size={18} stroke={1.5} />
    </ActionIcon>
  ));

  const onSelectMember = useCallback(({ memberId, longitude, latitude }) => {
    logMemberEvent.mutate({ event: "map", member: memberId });
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 2000,
      zoom: 15,
    });
    //log the search
  }, []);

  const actions = members
    ?.sort((a, b) => {
      const nameA = a.Name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.Name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }) //Sort member alphabetically
    ?.sort((a, b) => {
      const nameA = a.Category.toUpperCase(); // ignore upper and lowercase
      const nameB = b.Category.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }) //Sort category alphabetically
    .map((member) => {
      return {
        title: member.Name,
        description: member.DirectoryText,
        group: member.Category,
        type: member.Type,
        onTrigger: () => {
          onSelectMember({
            memberId: member.ROWID,
            longitude: parseFloat(member.Longitude.toString()),
            latitude: parseFloat(member.Latitude.toString()),
          });
        },
      };
    });

  return (
    <>
      <Header height={56} mb={120}>
        <Flex
          align="center"
          justify="space-between"
          direction="row"
          wrap="wrap"
          px="md"
          h={56}
        >
          <Group>
            <ChamberLogo />

            <MediaQuery smallerThan="md" styles={{ display: "none" }}>
              <Group>
                <Group spacing={0} className={classes.social} noWrap>
                  {socials}
                </Group>

                <Anchor
                  className={classes.link}
                  component={Button}
                  href="https://www.pecchamber.com"
                  target="_blank"
                >
                  Go To PEC Chamber
                  <IconExternalLink
                    size={rem(14)}
                    stroke={1.5}
                    style={{ marginLeft: rem(4) }}
                  />
                </Anchor>
                {user && (
                  // <Group spacing={5}>
                  <>
                    {location.pathname === "/" ? (
                      <Link className={classes.link} to="/secured/dashboard">
                        Admin Area
                      </Link>
                    ) : (
                      <Link className={classes.link} to="/">
                        Map
                      </Link>
                    )}
                    <Text className={classes.link} onClick={logout}>
                      <Center>
                        <Text mr={4}>Sign Out</Text>
                        <IconLogout size={16} stroke={1.5} />
                      </Center>
                    </Text>
                  </>
                  //  </Group>
                )}
              </Group>
            </MediaQuery>
          </Group>

          <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
            <Group>
              {/* <Group spacing={5} w={rem(350)}> */}
              <ActionIcon onClick={open}>
                <IconHelpCircle />
              </ActionIcon>
              <div style={{ flexGrow: 1 }}>
                <SearchControl />
              </div>
            </Group>
          </MediaQuery>

          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger opened={mobileMenuOpened} onClick={openMobileMenu} />
          </MediaQuery>
        </Flex>
      </Header>

      <Drawer
        opened={mobileMenuOpened}
        onClose={closeMobileMenu}
        overlayProps={{ opacity: 0.5, blur: 4 }}
        position="top"
        size="auto"
        title={
          <Anchor
            className={classes.link}
            component={Button}
            href="https://www.pecchamber.com"
            target="_blank"
          >
            Go To PEC Chamber
            <IconExternalLink
              size={rem(14)}
              stroke={1.5}
              style={{ marginLeft: rem(4) }}
            />
          </Anchor>
        }
      >
        <Group spacing={0} className={classes.social} noWrap grow>
          {socials}
        </Group>

        {user && (
          // <Group spacing={5}>
          <>
            {location.pathname === "/app/" ? (
              <Link className={classes.link} to="/secured/dashboard">
                Admin Area
              </Link>
            ) : (
              <Link className={classes.link} to="/app/">
                Map
              </Link>
            )}
            <Text className={classes.link} onClick={logout}>
              <Center>
                <Text mr={4}>Sign Out</Text>
                <IconLogout size={16} stroke={1.5} />
              </Center>
            </Text>
          </>
          //  </Group>
        )}

        <Group mt="md">
          <ActionIcon onClick={open}>
            <IconHelpCircle />
          </ActionIcon>
          <div style={{ flexGrow: 1 }}>
            <SearchControl />
          </div>
        </Group>
      </Drawer>

      <SpotlightProvider
        actions={actions ?? []}
        actionComponent={CustomAction}
        searchIcon={<IconSearch size="1.2rem" />}
        searchPlaceholder="Search..."
        shortcut="mod + shift + 1"
        nothingFoundMessage="Member not found."
        limit={1000}
        highlightQuery
        styles={{
          actionsGroup: { fontWeight: 700, fontSize: 12, textAlign: "center" },
        }}
      />
      <Modal
        opened={opened}
        onClose={handleWelcomeClose}
        title={
          <Text fw="bold">
            Welcome to the PEC Chamber Map and Business Finder
          </Text>
        }
        closeOnClickOutside={false}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack>
          <Text>
            To navigate the member list, use the search in the header or select
            a business category using the layer icon on the right.
          </Text>
          <Text>Click on any member to locate them on the map.</Text>
          <Text>Pins on the map display member details when clicked.</Text>
          <Text>
            Adjust map settings on the right to reset position, zoom, and select
            member categories.
          </Text>
          <Text>
            Reopen instructions anytime by clicking the help icon near the
            search bar.
          </Text>
        </Stack>
        <Button fullWidth onClick={handleWelcomeClose}>
          Close
        </Button>
      </Modal>
    </>
  );
}

const useStyles2 = createStyles((theme) => ({
  action: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: `${rem(10)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[1],
    }),

    "&[data-hovered]": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[1],
    },
  },
}));

function CustomAction({
  action,
  styles,
  classNames,
  hovered,
  onTrigger,
  ...others
}) {
  const { classes } = useStyles2(null, {
    styles,
    classNames,
    name: "Spotlight",
  });

  return (
    <UnstyledButton
      className={classes.action}
      data-hovered={hovered || undefined}
      tabIndex={-1}
      onMouseDown={(event) => event.preventDefault()}
      onClick={onTrigger}
      {...others}
    >
      <Group noWrap>
        <div style={{ flex: 1 }}>
          <Text
            color={action.type === "Community Investor" ? "red" : null}
            transform={
              action.type === "Community Investor" ? "uppercase" : null
            }
            weight="semibold"
          >
            {action.title}
          </Text>
          <Text
            color={action.type === "Community Investor" ? "red" : "dimmed"}
            size="xs"
          >
            {action.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
}
