import "./App.css";
import Map from "./pages/map";
import Map2 from "./pages/map2";
import Dashboard from "./pages/dashboard";
import Reports from "./pages/reports";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/main-layout";
import SignIn from "./pages/signin";
import { useEffect, useRef, useState } from "react";
import AdManagement from "./pages/ads";
import { ProtectedLayout } from "./components/protected-layout";
import { useAuth } from "./hooks/useAuth";

function App() {
  const { login } = useAuth();
  const mapRef = useRef();

  useEffect(() => {
    window.catalyst.auth
      .isUserAuthenticated()
      .then((data) => {
        login(data.content);
      })
      .catch((err) => console.log(err));
  }, []);

  //TODO structure map popup
  //TODO test ad resizing
  //TODO layer icons

  return (
    <Routes>
      <Route path="/" element={<MainLayout mapRef={mapRef} />}>
        {/* <Route path="/app/" element={<Map />} /> */}
        <Route path="/" element={<Map2 mapRef={mapRef} />} />
        <Route path="/signin" element={<SignIn />} />
      </Route>

      <Route path="/secured/" element={<ProtectedLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="reports" element={<Reports />} />
        <Route path="ads" element={<AdManagement />} />
      </Route>
    </Routes>
  );
}

export default App;
