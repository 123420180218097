import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <HashRouter>
      <AuthProvider>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colors: {
              "pecc-light-blue": [
                "#E8F6FD",
                "#BEE7F9",
                "#94D8F5",
                "#6AC8F1",
                "#40B9ED",
                "#16A9E9",
                "#1187BB",
                "#0D668C",
                "#09445D",
                "#04222F",
              ],
              layerBlue: [
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
                "#28b0eb",
              ],
              layerPurple: [
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
                "#652d90",
              ],
              layerRed: [
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
                "#DD1E25",
              ],
              layerOrange: [
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
                "#F68F22",
              ],
              layerSkin: [
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
                "#F6E28F",
              ],
              layerGreen: [
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
                "#7BC846",
              ],
            },
            primaryColor: "pecc-light-blue",
          }}
        >
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MantineProvider>
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
