import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  useControl,
  Source,
  Layer,
} from "react-map-gl";

import {
  Anchor,
  Center,
  Image,
  Modal,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCategoryStore } from "../stores/useCategoryStore";
import { IconExternalLink } from "@tabler/icons-react";

import Reset from "../components/mapControls/resetControl";
import Categories from "../components/mapControls/categoryControl/categoryControl";
import "mapbox-gl/dist/mapbox-gl.css";
import MemberDetails from "../components/member-details";
import { useMemberStore } from "../stores/useMemberStore";
import { useDisclosure, useInterval, useLocalStorage } from "@mantine/hooks";
import { useRouteStore } from "../stores/useRoutesStore";

const MapPage = (props) => {
  const { mapRef } = props;
  const [opened, { open, close }] = useDisclosure(false);

  const adData = useQuery({
    queryKey: ["adData"],
    queryFn: async () => {
      const response = await fetch("/server/controller-ad/");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    enabled: false,
    retry: 0,
  });

  const logMemberEvent = useMutation({
    mutationKey: ["logMemberEvent3"],
    mutationFn: async ({ event, member }) => {
      const response = await fetch(`/server/controller-member/event`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: event,
          member: member,
        }),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
  });

  const interval = useInterval(() => adHandler(), 1000 * 60 * 5);

  const adHandler = async () => {
    await adData.refetch();
    if (adData) {
      open();
    }
  };

  useEffect(() => {
    adHandler();
  }, []);

  useEffect(() => {
    if (opened) {
      interval.stop();
    } else {
      interval.start();
    }
  }, [opened]);

  return (
    <>
      <CustomMap mapRef={mapRef} />
      <Modal
        opened={opened}
        onClose={close}
        title={`Brought to you by: ${adData.data?.Members.Name}`}
      >
        {adData.data?.Ads.Image && (
          <Anchor
            href={adData.data?.Ads.URL}
            target="_blank"
            onClick={() =>
              logMemberEvent.mutate({
                event: "adClick",
                member: adData.data?.Ads.Member,
              })
            }
          >
            <Image src={`/app/images/ads/${adData.data?.Ads.Image}`} />
          </Anchor>
        )}
      </Modal>
    </>
  );
};

export default MapPage;

const CustomMap = ({ mapRef }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [details, setDetails] = useState();
  const { setCategories, categories } = useCategoryStore();
  const { routes } = useRouteStore();

  const groupedMembers = useQuery({
    queryKey: ["groupedMembers"],
    queryFn: async () => {
      const response = await fetch("/server/controller-member/grouped");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  });

  const comfortStationsData = useQuery({
    queryKey: ["comfortStations"],
    queryFn: async () => {
      const response = await fetch("/server/controller-route/comfort");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  });

  const evStationsData = useQuery({
    queryKey: ["evStations"],
    queryFn: async () => {
      const response = await fetch("/server/controller-route/ev");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  });

  const logMemberEvent = useMutation({
    mutationKey: ["logMemberEvent"],
    mutationFn: async ({ event, member }) => {
      const response = await fetch(`/server/controller-member/event`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: event,
          member: member,
        }),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
  });

  useEffect(() => {
    setCategories(
      groupedMembers.data?.data?.map((category) => {
        return {
          id: category.categoryId,
          name: category.name,
          isActive: true,
        };
      })
    );
    // setMembers(
    //   groupedMembers.data?.data?.flatMap((category) => {
    //     return category.members?.map((member) => {
    //       return { ...member };
    //     });
    //   })
    // );
  }, [groupedMembers.data]);

  const evStations = evStationsData.data?.rows.map((evStation, index) => (
    <Marker
      key={`evMarker-${index}`}
      longitude={evStation.Longitude}
      latitude={evStation.Latitude}
      anchor="bottom"
    >
      <Center>
        <ThemeIcon c="orange" variant="transparent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-map-pin-bolt"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
            <path d="M13.414 20.9a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 13.591 -4.629" />
            <path d="M19 16l-2 3h4l-2 3" />
          </svg>
        </ThemeIcon>
      </Center>
    </Marker>
  ));

  const comfortStations = comfortStationsData.data?.rows.map(
    (comfortStation, index) => (
      <Marker
        key={`evMarker-${index}`}
        longitude={comfortStation.Longitude}
        latitude={comfortStation.Latitude}
        anchor="bottom"
      >
        <Tooltip label={comfortStation.Name}>
          <Center>
            {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-map-pin-bolt"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
          <path d="M13.414 20.9a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 13.591 -4.629" />
          <path d="M19 16l-2 3h4l-2 3" />
        </svg> */}
            <img src={comfortStation.Icon} height={24} />
          </Center>
        </Tooltip>
      </Marker>
    )
  );

  const groupedPins = groupedMembers.data?.data?.map((category, index) => {
    return {
      categoryId: category.categoryId,
      members: (
        <div key={`markerCategory-${category.categoryId}-${index}`}>
          {category.members.map((member, index) => (
            <Marker
              key={`markerMember-${category.categoryId}-${index}`}
              longitude={member.Longitude}
              latitude={member.Latitude}
              anchor="bottom"
              onClick={(e) => {
                // If we let the click event propagates to the map, it will immediately close the popup
                // with `closeOnClick: true`
                e.originalEvent.stopPropagation();
                //setPopupInfo(member);
                setDetails(member);
                setModalOpened(true);
                //log pin click
              }}
            >
              {/* <Pin /> */}
              <Tooltip label={member.Name}>
                <UnstyledButton sx={{ color: category.color }}>
                  <Center>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-map-pin-filled"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        stroke="#000"
                        strokeWidth={1}
                        d="M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Center>
                </UnstyledButton>
              </Tooltip>
            </Marker>
          ))}
        </div>
      ),
    };
  });

  const mapDefaults = {
    latitude: 44.00012,
    longitude: -77.13275,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  };

  return (
    <>
      <Map
        ref={mapRef}
        initialViewState={mapDefaults}
        style={{ width: "100%", height: "calc(100vh - 56px)" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={
          "pk.eyJ1IjoicGVjY2hhbWJlciIsImEiOiJjbHRlc2Z4NXAwMzVvMmxyenNtdjUyODVrIn0.2iRWEOKliUG6yuxG-zIFDg"
        }
      >
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        <ResetControl
          lng={mapDefaults.longitude}
          lat={mapDefaults.latitude}
          zoom={mapDefaults.zoom}
        />
        <CategoriesControl />

        {routes.find((route) => route.id === "bike").isActive && (
          <Source
            id="my-data"
            type="geojson"
            data="/app/geoJson/bikeRoutes.geojson"
          >
            <Layer
              id="point"
              type="line"
              paint={{ "line-color": "forestgreen", "line-width": 4 }}
            />
          </Source>
        )}

        {groupedPins?.map((group) => {
          if (categories?.find((x) => x.id == group.categoryId)?.isActive)
            return group.members;
        })}

        {routes.find((route) => route.id === "comfort").isActive && (
          <>{comfortStations}</>
        )}

        {routes.find((route) => route.id === "ev").isActive && (
          <>{evStations}</>
        )}
      </Map>

      {details && (
        <MemberDetails
          opened={modalOpened}
          setOpened={setModalOpened}
          details={details}
          mapRef={mapRef}
        />
      )}
    </>
  );
};

// const MemberDetails = ({ member, setPopupInfo }) => {
//   console.log(member);
//   return (
//     <Popup
//       anchor="top"
//       maxWidth="100%"
//       longitude={Number(member.Longitude)}
//       latitude={Number(member.Latitude)}
//       onClose={() => setPopupInfo(null)}
//     >
//       {/* <div>
//         {popupInfo.properties.Name}, {popupInfo.properties.secondaryCategories} |{' '}
//         <a
//           target="_new"
//           href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${popupInfo.city}, ${popupInfo.state}`}
//         >
//           Wikipedia
//         </a>
//       </div> */}
//       <Title order={2} align="center">
//         {member.Name}
//       </Title>
//       <Stack>
//         <Group position="center" spacing={2}>
//           <Badge color="red">{member.Category}</Badge>
//         </Group>
//         {member.additionalCategories && (
//           <Group position="center" spacing={2}>
//             {member.Categories.map((category) => (
//               <Badge key={category} color="blue">
//                 {category}
//               </Badge>
//             ))}
//           </Group>
//         )}
//       </Stack>

//       {/* <p>{member.Description}</p> */}
//       {member.type === "Community Investor" && <p>{member.description}</p>}
//       {/* <p>{member.Address}</p> */}

//       {/* <Group mb={20}>{member.socials}</Group> */}

//       <Group>
//         {/* {Latitude && <Button onClick={showOnMap}>Show on Map</Button>} */}
//         {member.website && (
//           <Button
//             component="a"
//             href={member.website}
//             target="_blank"
//             leftIcon={<IconExternalLink size={14} />}
//           >
//             View Site
//           </Button>
//         )}
//         {member.address && (
//           <Button
//             component="a"
//             href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
//               member.address
//             )}&travelmode=driving`}
//             target="_blank"
//             leftIcon={<IconExternalLink size={14} />}
//           >
//             Get Directions
//           </Button>
//         )}
//       </Group>
//     </Popup>
//   );
// };

const ResetControl = (props) => {
  useControl(
    () =>
      new Reset({
        zoom: props.zoom,
        lng: props.lng,
        lat: props.lat,
      }),
    {
      position: "top-right",
    }
  );
  return null;
};

const CategoriesControl = (props) => {
  useControl(() => new Categories({ categories: props.categories }), {
    position: "top-right",
  });
  return null;
};
