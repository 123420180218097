import { Image } from "@mantine/core"

const ChamberLogo = ({size=50}) => {

    return (
        <a href="https://www.pecchamber.com/" target="_blank">
        <Image width={size} fit='contain' src='/app/PECCoC-Logo-FINAL-1.png' alt="Chamber of Commerce Logo" />
        </a>
    )
}

export default ChamberLogo