import { createStyles, Container, Anchor, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import ChamberLogo from "./chamber-logo";

const useStyles = createStyles((theme) => ({
  footer: {
    //marginTop: 120,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function FooterSimple() {
  const { user } = useAuth();
  const { classes } = useStyles();

  return (
    <div id="footer" className={classes.footer}>
      <Container className={classes.inner}>
        <ChamberLogo size={80} />
        {!user && (
          <Text weight={500} color="dimmed" component={Link} to="/signin">
            Sign In
          </Text>
        )}

        <Text>
          Developed by{" "}
          <Anchor href="http://smarturl.c3-solutions.ca/peccm" target="_blank">
            C3 Solutions
          </Anchor>
        </Text>
        {/* <Group className={classes.links}>{items}</Group> */}
      </Container>
    </div>
  );
}
