import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppShell } from "@mantine/core";
import { HeaderMiddle } from "./header";
import { FooterSimple } from "./footer";
import Nav from "./navbar";

export const ProtectedLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/app/" />;
  }

  return (
    <AppShell padding="md" header={<HeaderMiddle />} footer={<FooterSimple />} navbar={<Nav />}>
        <Outlet />
    </AppShell>
)
};
