import ReactDOM from "react-dom";
import Base from "../base";
import Button from "../button/button";
import { Menu } from "./categoryMenu";
import React, { Dispatch, SetStateAction } from "react";
import { LngLat } from "react-map-gl";
import { createRoot } from "react-dom/client";
// import iconPlus from '../icons/ts/plus';
// import iconMinus from '../icons/ts/minus';

export default class CategoryControl extends Base {
  // buttonCategories: Button;
  // categories: Category[]
  // popupNode: HTMLDivElement;
  // isOpen = false;
  // lngLat: LngLat

  constructor(options) {
    super();
    this.buttonCategories = new Button();
    this.categories = options.categories;
    this.isOpen = false;
  }

  insert() {
    const buttonLocation = this.buttonCategories.node.getBoundingClientRect();
    const element = document.createElement("div");
    element.id = "menuuu";
    element.style.position = "fixed";
    element.style.top = buttonLocation.height + "px";
    element.style.right = "0px";
    this.popupNode = element;
    const root = createRoot(element);
    root.render(
      React.createElement(
        Menu,
        { categories: this.categories, _this: this },
        null
      )
    );

    this.addClassName("mapbox-categories");
    this.buttonCategories.setIcon(iconCategories()).onClick(() => {
      this.showMenu();
    });
    this.addButton(this.buttonCategories);
  }

  showMenu() {
    if (!this.popupNode) return;
    this.node.appendChild(this.popupNode);

    this.node.classList.remove("mapboxgl-ctrl-group");
  }

  hideMenu() {
    this.node.replaceChildren();
    this.node.classList.add("mapboxgl-ctrl-group");
    this.addButton(this.buttonCategories);
  }

  onAddControl() {
    this.insert();
  }
}

const svg = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-stack-2" color="#333333" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
<path d="M12 4l-8 4l8 4l8 -4l-8 -4"></path>
<path d="M4 12l8 4l8 -4"></path>
<path d="M4 16l8 4l8 -4"></path>
</svg>`;

const iconCategories = () =>
  new DOMParser().parseFromString(svg, "image/svg+xml").firstChild;
