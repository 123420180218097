import { createStyles, Paper, Image, getStylesRef } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";

const useStyles = createStyles((_theme, _params) => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

export const BannerAd = () => {
  return (
    <Paper
      withBorder
      shadow="sm"
      style={{
        width: "100%",
        height: "50px",
        overflow: "hidden",
        marginBottom: "10px",
      }}
    >
      <Image src="https://via.placeholder.com/406x178?text=Prime Ad 406x178" />
    </Paper>
  );
};

export const PrimeAd = () => {
  return (
    // <Paper withBorder shadow="sm" style={{ width: "590px", height: "259px", overflow: "hidden" }}>
    <Paper
      withBorder
      shadow="sm"
      style={{ width: "406px", height: "178px", overflow: "hidden" }}
    >
      {/* <Image src={`https://via.placeholder.com/500x200?text=Prime+Ad`}></Image> */}
      {/* <Image src="./images/PECish.png" fit="contain"  ></Image> */}
      <Image src="https://via.placeholder.com/406x178?text=Prime Ad 406x178" />
    </Paper>
  );
};

export const PrimeAdCycle = (props) => {
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const { ads = ["Ad 1", "Ad 2"] } = props;

  const slides = ads?.map((ad) => (
    <Carousel.Slide key={ad}>
      <Image
        src={`https://via.placeholder.com/406x178?text=Standard Ad 406x178`}
      ></Image>
    </Carousel.Slide>
  ));
  return (
    <>
      {slides.length > 0 && (
        // <Paper withBorder shadow="sm" style={{ width: "370px", height: "192px", overflow: "hidden" }}>
        <Paper
          withBorder
          shadow="sm"
          style={{ width: "406px", height: "178px", overflow: "hidden" }}
        >
          <Carousel
            loop
            draggable={false}
            withControls={true}
            classNames={classes}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {slides}
          </Carousel>
        </Paper>
      )}
    </>
  );
};

export const PremiumAd = (props) => {
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const {
    ads = ["The-Eddie.jpg", "Crombie_Ad.jpg", "14-Jan-Royal-County-Map_Ad.png"],
  } = props;

  const slides = ads?.map((ad) => (
    <Carousel.Slide key={ad}>
      {/* <Image src={`https://via.placeholder.com/242x400?text=Premium+${ad}`}></Image> */}
      <Image src={`./images/${ad}`}></Image>
    </Carousel.Slide>
  ));
  return (
    <>
      {slides.length > 0 && (
        <Paper
          withBorder
          shadow="sm"
          style={{ width: "190px", height: "325px", overflow: "hidden" }}
        >
          <Carousel
            loop
            draggable={false}
            withControls={true}
            classNames={classes}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {slides}
          </Carousel>
        </Paper>
      )}
    </>
  );
};

export const StandardAd = (props) => {
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const {
    ads = [
      "Ad 1",
      "Ad 2",
      "Ad 3",
      "Ad 4",
      "Ad 5",
      "Ad 6",
      "Ad 7",
      "Ad 8",
      "Ad 9",
    ],
  } = props;

  const slides = ads?.map((ad) => (
    <Carousel.Slide key={ad}>
      <Image
        src={`https://via.placeholder.com/260x135?text=Standard Ad 260x135`}
      ></Image>
    </Carousel.Slide>
  ));
  return (
    <>
      {slides.length > 0 && (
        // <Paper withBorder shadow="sm" style={{ width: "370px", height: "192px", overflow: "hidden" }}>
        <Paper
          withBorder
          shadow="sm"
          style={{ width: "260px", height: "135px", overflow: "hidden" }}
        >
          <Carousel
            loop
            draggable={false}
            withControls={true}
            classNames={classes}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {slides}
          </Carousel>
        </Paper>
      )}
    </>
  );
};

export const GenericAd = (props) => {
  const { classes } = useStyles();
  const { type, ads = [], root } = props;

  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const height = type == "cover" ? "320px" : "135px";

  const logAdEvent = (member) => {
    if (member.length === 0) return;

    fetch("/server/controller-member/event", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: "ad",
        member: member,
      }),
    })
      .then((data) => {})
      .catch((err) => console.log(err));
  };

  const slides = ads?.map((ad, idx) => {
    if (ad.length === 0) {
      ad = `https://via.placeholder.com/260x${parseInt(
        height.replace("px", "")
      )}?text=${type[0].toUpperCase() + type.substring(1)} Ad ${
        idx + 1
      } 260x${height.replace("px", "")}`;
    }
    return (
      <Carousel.Slide key={idx}>
        {ad.url ? (
          <a
            href={ad.url}
            target="_blank"
            onClick={() => {
              logAdEvent(ad.member);
            }}
          >
            <Image src={root + ad.image}></Image>
          </a>
        ) : (
          <Image src={root + ad.image}></Image>
        )}
      </Carousel.Slide>
    );
  });
  return (
    <>
      {slides.length > 0 && (
        <Paper
          withBorder
          shadow="sm"
          style={{ width: "260px", height: height, overflow: "hidden" }}
        >
          <Carousel
            loop
            draggable={false}
            withControls={slides.length > 1 ? true : false}
            classNames={classes}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {slides}
          </Carousel>
        </Paper>
      )}
    </>
  );
};
