import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Group,
  Title,
  Badge,
  Stack,
  ActionIcon,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandInstagram,
  IconBrandYoutube,
  IconExternalLink,
} from "@tabler/icons-react";

const MemberDetails = (props) => {
  const { Longitude, Latitude } = props.details;
  const member = props.details;

  const [opened, setOpened] = useState(false);

  const logEvent = (event = "view") => {
    fetch("/server/controller-member/event", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: event,
        member: member.ROWID,
      }),
    })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (props.opened) logEvent();
  }, [props.opened]);

  const showOnMap = () => {
    // props.setZoom(20);
    // props.setCenter({ lat: parseFloat(Latitude), lng: parseFloat(Longitude) });
    // props.setOpened(false);
    // props.setDirectoryOpened(false);
    props.mapRef.current?.flyTo({
      center: [Longitude, Latitude],
      duration: 2000,
      zoom: 15,
    });

    logEvent("map");
  };

  const additionalCategories = member.Categories?.replace(/[\x5B\x5D]/g, "")
    .split(", ")
    .map((category) => <Badge key={category}>{category}</Badge>);

  console.log(member);
  const rawSocials = [
    {
      link: member.Facebook,
      icon: IconBrandFacebook,
    },
    {
      link: member.Google,
      icon: IconBrandGoogle,
    },
    {
      link: member.Instagram,
      icon: IconBrandInstagram,
    },
    {
      link: member.Twitter,
      icon: IconBrandTwitter,
    },
    {
      link: member.YouTube,
      icon: IconBrandYoutube,
    },
    {
      link: member.LinkedIn,
      icon: IconBrandLinkedin,
    },
  ];
  const socials = rawSocials
    .filter((social) => social.link != null)
    .map((social) => (
      <ActionIcon
        key={social.icon}
        variant="outline"
        component="a"
        href={social.link}
        target="_blank"
      >
        <social.icon />
      </ActionIcon>
    ));

  return (
    <>
      <Modal
        centered
        size="xl"
        opened={props.opened}
        onClose={() => props.setOpened(false)}
      >
        <Title order={2} align="center">
          {member.Name}
        </Title>
        <Stack>
          <Group position="center" spacing={2}>
            <Badge color="red">{member.Category}</Badge>
          </Group>
          {additionalCategories && (
            <Group position="center" spacing={2}>
              {additionalCategories}
            </Group>
          )}
        </Stack>

        {/* <p>{member.Description}</p> */}
        {member.Type === "Community Investor" && <p>{member.Description}</p>}
        {/* <p>{member.Address}</p> */}

        <Group my={20} position="center">
          {socials}
        </Group>

        <Group position="center">
          {Latitude && <Button onClick={showOnMap}>Show on Map</Button>}
          {member.Website && (
            <Button
              component="a"
              href={
                member.Website.startsWith("http://") ||
                member.Website.startsWith("https://")
                  ? member.Website
                  : `https://${member.Website}`
              }
              target="_blank"
              onClick={() => {
                logEvent("site");
              }}
              leftIcon={<IconExternalLink size={14} />}
            >
              View Site
            </Button>
          )}
          {member.Address && (
            <Button
              component="a"
              href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                member.Address
              )}&travelmode=driving`}
              target="_blank"
              onClick={() => {
                logEvent("directions");
              }}
              leftIcon={<IconExternalLink size={14} />}
            >
              Get Directions
            </Button>
          )}
        </Group>
      </Modal>
    </>
  );
};

export default MemberDetails;
