import { create } from "zustand";

export const useRouteStore = create()((set) => ({
  routes: [
    { id: "bike", name: "Bike Routes", isActive: false },
    { id: "comfort", name: "Comfort Stations", isActive: false },
    { id: "ev", name: "EV Stations", isActive: false },
  ],
  setRoutes: (routes) => set((state) => ({ routes: routes })),
  updateRoute: (route) =>
    set((state) => ({
      routes: state.routes.map((rt) =>
        rt.name === route.name
          ? {
              ...rt,
              isActive: route.isActive,
            }
          : rt
      ),
    })),
  enableAllRoutes: () =>
    set((state) => ({
      routes: state.routes.map((rt) => {
        return {
          ...rt,
          isActive: true,
        };
      }),
    })),
  disableAllRoutes: () =>
    set((state) => ({
      routes: state.routes.map((rt) => {
        return {
          ...rt,
          isActive: false,
        };
      }),
    })),
}));
