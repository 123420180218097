import { create } from "zustand";

// type Category = {
//     id: string
//     name: string
//     isActive: boolean
// }

// interface CategoryState {
//     isAllEnabled: boolean
//     categories: Category[]
//     setCategories: (categories: Category[]) => void
//     updateCategory: (category: Category) => void
//     enableAllCategories: () => void
//     disableAllCategories: () => void
// }

export const useCategoryStore = create()((set) => ({
  categories: [],
  isAllEnabled: true,
  setCategories: (categories) => set((state) => ({ categories: categories })),
  updateCategory: async (category) => {
    console.log("Log Category Select Here. value:", category.name);
    fetch(`/server/controller-category/event`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: "view",
        category: category.name,
      }),
    });
    set((state) => ({
      categories: state.categories.map((cat) =>
        cat.name === category.name
          ? {
              ...cat,
              isActive: category.isActive,
            }
          : cat
      ),
    }));
  },
  enableAllCategories: () =>
    set((state) => ({
      isAllEnabled: true,
      categories: state.categories.map((cat) => {
        return {
          ...cat,
          isActive: true,
        };
      }),
    })),
  disableAllCategories: () =>
    set((state) => ({
      isAllEnabled: false,
      categories: state.categories.map((cat) => {
        return {
          ...cat,
          isActive: false,
        };
      }),
    })),
}));
