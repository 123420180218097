import { useLocalStorage } from "@mantine/hooks";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage({
    key: "user",
    defaultValue: null,
  });
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate("/secured/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    var redirectURL =
      "http://" + document.domain + ":" + window.location.port + "/app/";
    window.catalyst.auth.signOut(redirectURL);
    //navigate("/app/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
