import { Navbar, NavLink } from "@mantine/core";
import {
  IconDashboard,
  IconChartInfographic,
  IconBadgeAd,
} from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";

const data = [
  { link: "/secured/dashboard", label: "Dashboard", icon: IconDashboard },
  {
    link: "/secured/reports",
    label: "Reports",
    icon: IconChartInfographic,
  },
  { link: "/secured/ads", label: "Ad Management", icon: IconBadgeAd },
];

const Nav = () => {
  const location = useLocation();

  const links = data.map((item) => (
    <NavLink
      key={item.label}
      label={item.label}
      variant="light"
      icon={<item.icon size={16} stroke={1.5} />}
      component={Link}
      to={item.link}
      active={location.pathname === item.link}
    />
  ));

  return (
    <Navbar height={700} width={{ sm: 300 }} p="sm">
      <Navbar.Section grow>{links}</Navbar.Section>
    </Navbar>
  );
};

export default Nav;
