import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Group,
  Paper,
  Radio,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useCategoryStore } from "../../../stores/useCategoryStore";
import { useRouteStore } from "../../../stores/useRoutesStore";
//import { useRouteStore } from "~/stores/useRoutesStore";
//import { useSpecialFiltersStore } from "~/stores/useSpecialFiltersStore";

export const Menu = (props) => {
  const theme = useMantineTheme();
  const isNotMobile = useMediaQuery("(min-width: 64em)");
  const {
    categories: categoriesData,
    isAllEnabled,
    updateCategory,
    enableAllCategories,
    disableAllCategories,
  } = useCategoryStore();
  // const {
  //   specialFilters: specialFiltersData,
  //   updateSpecialFilters,
  //   disableAllSpecialFilters,
  // } = useSpecialFiltersStore();
  const { routes: routesData, updateRoute } = useRouteStore();

  useEffect(() => {
    if (categoriesData?.filter((cat) => cat.isActive).length > 0) {
      //disableAllSpecialFilters();
    }
  }, [categoriesData]);

  // useEffect(() => {
  //   if (specialFiltersData.filter((f) => f.isActive).length > 0) {
  //     disableAllCategories();
  //   }
  // }, [specialFiltersData]);

  const categories = useMemo(
    () =>
      categoriesData?.map((category, index) => {
        return (
          <Checkbox
            key={`category:${index}`}
            label={category.name}
            checked={isAllEnabled ? false : category.isActive}
            onChange={(event) => {
              if (isAllEnabled) {
                disableAllCategories();
                updateCategory({
                  id: category.id,
                  name: category.name,
                  isActive: event.currentTarget.checked,
                });
              } else {
                updateCategory({
                  id: category.id,
                  name: category.name,
                  isActive: event.currentTarget.checked,
                });
              }
            }}
          />
        );
      }),
    [categoriesData]
  );

  // const specialFilters = useMemo(
  //   () =>
  //     specialFiltersData.map((specialFilter, index) => {
  //       return (
  //         <Radio
  //           key={`specialFilter:${index}`}
  //           label={specialFilter.name}
  //           checked={specialFilter.isActive}
  //           onClick={(event) =>
  //             event.currentTarget.checked ? disableAllSpecialFilters() : null
  //           }
  //           onChange={(event) =>
  //             updateSpecialFilters({
  //               id: specialFilter.id,
  //               name: specialFilter.name,
  //               isActive: event.currentTarget.checked,
  //             })
  //           }
  //         />
  //       );
  //     }),
  //   [specialFiltersData]
  // );

  const routes = useMemo(
    () =>
      routesData.map((route, index) => {
        return (
          <Checkbox
            key={`route:${index}`}
            label={route.name}
            checked={route.isActive}
            onChange={(event) => {
              updateRoute({
                id: route.id,
                name: route.name,
                isActive: event.currentTarget.checked,
              });
            }}
          />
        );
      }),
    [routesData]
  );

  return (
    <Paper
      className="mapbox-control-inspect-popup"
      withBorder
      shadow="xl"
      p={5}
      w={isNotMobile ? 300 : `calc(100vw - ${theme.spacing.lg})`}
    >
      <Group position="apart">
        <Title order={4}>Categories</Title>
        <ActionIcon size="lg" onClick={() => props._this.hideMenu()}>
          <IconX />
        </ActionIcon>
      </Group>
      <Stack>
        <div>
          <Divider
            my="xs"
            label="Traffic, Routes, & Rest"
            labelPosition="center"
          />
          <Stack spacing="xs">{routes}</Stack>
        </div>

        <div>
          <Divider my="xs" label="Member Types" labelPosition="center" />
          <Stack spacing="xs">
            <Checkbox
              label="All"
              checked={isAllEnabled}
              onChange={(event) => {
                event.currentTarget.checked
                  ? enableAllCategories()
                  : disableAllCategories();
              }}
            />
            {categories}
          </Stack>
        </div>

        {/* <div>
          <Divider my="xs" label="Special Filters" labelPosition="center" />
          <Stack spacing="xs">{specialFilters}</Stack>
        </div> */}
      </Stack>
    </Paper>
  );
};
