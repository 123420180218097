import {
  Box,
  Button,
  Grid,
  Radio,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useMemo, useState } from "react";
import { DatePicker } from "@mantine/dates";
import dayjs, { Dayjs } from "dayjs";
import { IconDownload, IconSearch } from "@tabler/icons-react";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import sortBy from "lodash/sortBy";
import { useQuery } from "@tanstack/react-query";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_EditActionButtons,
  MRT_ExpandedState,
} from "mantine-react-table";

import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const Reports = () => {
  return (
    <>
      <Title order={3}>Member Events</Title>
      <MemberEventsTable />

      <Title order={3} mt="lg">
        Category Events
      </Title>
      <CategoryEventsTable />
    </>
  );
};

export default Reports;

// const OriginalEventtable = () => {
//   const curYear = new Date().getFullYear();
//   const curMonth = new Date().getMonth();
//   const lastDay = new Date(curYear, curMonth + 1, 0).getDate();

//   const [dateRange, setDateRange] = useState([
//     new Date(curYear, curMonth, 1),
//     new Date(curYear, curMonth, lastDay),
//   ]);
//   const [fetching, setFetching] = useState(true);
//   const [initialRecords, setInitialRecords] = useState([]);
//   const [records, setRecords] = useState([]);
//   const [query, setQuery] = useState("");
//   const [debouncedQuery] = useDebouncedValue(query, 200);
//   const [filteredEvent, setFilteredEvent] = useState("*");
//   const [sortStatus, setSortStatus] = useState({
//     columnAccessor: "name",
//     direction: "asc",
//   });

//   useEffect(() => {
//     setFetching(true);
//     fetch(
//       "/server/controller-member/event?" +
//         new URLSearchParams({
//           from: dayjs(dateRange[0]).format("YYYY-MM-DD"),
//           to: dayjs(dateRange[1]).format("YYYY-MM-DD"),
//         })
//     )
//       .then((data) => data.json())
//       .then((data) => {
//         console.log(data);
//         const initRecs = data.map((event) => {
//           return {
//             member: event.Members.Name,
//             event: event.Auth_Events.Event,
//             count: event.Event_Log.ROWID,
//             //datetime: dayjs(event.Event_Log.CREATEDTIME).format("YYYY-MM-DD @ HH:mma"),
//           };
//         });
//         setInitialRecords(initRecs);
//         setRecords(initRecs);
//         setFetching(false);
//         setFilteredEvent("*");
//       })
//       .catch((err) => console.log(err));
//   }, [dateRange]);

//   //Search / Filtering
//   useEffect(() => {
//     setRecords(
//       initialRecords.filter(({ member, event, datetime }) => {
//         if (
//           filteredEvent !== "*" &&
//           filteredEvent.toLowerCase() !== event.toLowerCase()
//         ) {
//           return false;
//         }
//         if (
//           debouncedQuery !== "" &&
//           !`${member}`
//             .toLowerCase()
//             .includes(debouncedQuery.trim().toLowerCase())
//         ) {
//           return false;
//         }
//         return true;
//       })
//     );

//     let a = sortStatus;
//     setSortStatus(a);
//   }, [debouncedQuery, filteredEvent]);

//   //Ordering
//   useEffect(() => {
//     const data = sortBy(records, sortStatus.columnAccessor);
//     setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
//   }, [sortStatus]);

//   const columns = [
//     {
//       accessor: "member",
//       width: "50%",
//     },
//     {
//       accessor: "event",
//       width: "30%",
//     },
//     {
//       accessor: "count",
//       width: "20%",
//       sortable: true,
//     },
//   ];

//   return (
//     <>
//       <Grid align="center" mb="sm">
//         <Grid.Col span={3}>
//           <DatePicker
//             label="Report Dates"
//             value={dateRange}
//             onChange={setDateRange}
//           />
//         </Grid.Col>
//         <Grid.Col span={6}>
//           <TextInput
//             label="Search Member"
//             placeholder="Member"
//             icon={<IconSearch size={16} stroke={1.5} />}
//             value={query}
//             onChange={(e) => {
//               setQuery(e.currentTarget.value);
//             }}
//           />
//         </Grid.Col>
//         <Grid.Col span={3}>
//           <Radio.Group
//             name="favoriteFramework"
//             label="Filter Event"
//             spacing="xs"
//             value={filteredEvent}
//             onChange={setFilteredEvent}
//           >
//             <Radio value="*" label="All" />
//             <Radio value="view" label="View" />
//             <Radio value="map" label="Map" />
//             <Radio value="website" label="Website" />
//           </Radio.Group>
//         </Grid.Col>
//       </Grid>

//       <Box style={{ minHeight: "25%" }}>
//         <DataTable
//           withBorder
//           borderRadius="sm"
//           withColumnBorders
//           striped
//           highlightOnHover
//           minHeight={180}
//           columns={columns}
//           records={records}
//           fetching={fetching}
//           sortStatus={sortStatus}
//           onSortStatusChange={setSortStatus}
//         />
//       </Box>
//     </>
//   );
// };

const MemberEventsTable = () => {
  const curYear = new Date().getFullYear();
  const curMonth = new Date().getMonth();
  const lastDay = new Date(curYear, curMonth + 1, 0).getDate();
  const [dateRange, setDateRange] = useState([
    new Date(curYear, curMonth, 1),
    new Date(curYear, curMonth, lastDay),
  ]);

  const csvConfig = mkConfig({
    filename: "Map Member Events Export - " + dayjs().format("YYYY-MM-DD"),
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const { data: eventData } = useQuery({
    queryKey: ["eventData"],
    queryFn: async () => {
      const response = await fetch(
        "/server/controller-member/event?" +
          new URLSearchParams({
            from: dayjs(dateRange[0]).format("YYYY-MM-DD"),
            to: dayjs(dateRange[1]).format("YYYY-MM-DD"),
          })
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  });

  const columns = useMemo(
    () => [
      // {
      //     header: 'ID',
      //     accessorKey: 'id',
      //     enableGrouping: false, //do not let this column be grouped
      // },
      {
        header: "Member",
        accessorKey: "member",
        enableColumnFilter: false,
      },
      {
        header: "Event",
        accessorKey: "eventType",
        filterVariant: "multi-select",
        mantineFilterMultiSelectProps: {
          data: ["View", "Map", "Site", "Ad Engagement"],
        },
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        header: "Event DateTime",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          return date.toLocaleDateString();
        },
        aggregationFn: "max",
        AggregatedCell: ({ cell, table }) => (
          <>
            Most Recent:{" "}
            <Text sx={{ display: "inline", fontWeight: "bold" }} color="blue">
              {dayjs
                .utc(cell.getValue())
                .tz("America/Toronto")
                .format("MMM DD, YYYY @ hh:mma")}
            </Text>
          </>
        ),
      },
    ],
    []
  );

  const data =
    useMemo(() => {
      if (!eventData) return [];

      return eventData?.map((event) => {
        return {
          id: event.Event_Log.ROWID,
          member: event.Members.Name,
          eventType: event.Auth_Events.Event,
          createdAt: event.Event_Log.Event_DTS,
        };
      });
    }, [eventData]) ?? [];

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
    initialState: {
      density: "xs",
      grouping: ["member", "eventType"],
      showColumnFilters: true,
    },
    enableExpanding: true,
    enableGrouping: true,
    filterFromLeafRows: true,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="lightblue"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          leftIcon={<IconDownload />}
          variant="filled"
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  return <MantineReactTable table={table} />;
};

const CategoryEventsTable = () => {
  const curYear = new Date().getFullYear();
  const curMonth = new Date().getMonth();
  const lastDay = new Date(curYear, curMonth + 1, 0).getDate();
  const [dateRange, setDateRange] = useState([
    new Date(curYear, curMonth, 1),
    new Date(curYear, curMonth, lastDay),
  ]);

  const { data: eventData } = useQuery({
    queryKey: ["categoryEventData"],
    queryFn: async () => {
      const response = await fetch("/server/controller-category/event");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Fetched Data: ", data);
      return data;
    },
  });

  const csvConfig = mkConfig({
    filename: "Map Category Events Export - " + dayjs().format("YYYY-MM-DD"),
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const columns = useMemo(
    () => [
      {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        header: "Event DateTime",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          return date.toLocaleDateString();
        },
        aggregationFn: "max",
        AggregatedCell: ({ cell }) => (
          <>
            Most Recent:{" "}
            <Text sx={{ display: "inline", fontWeight: "bold" }} color="blue">
              {dayjs
                .utc(cell.getValue())
                .tz("America/Toronto")
                .format("MMM DD, YYYY @ hh:mma")}
            </Text>
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!eventData) return [];

    const mappedData = eventData.map((event) => {
      return {
        id: event.Category_Events.ROWID,
        category: event.Category_Events.Category,
        createdAt: new Date(event.Category_Events.Event_DTS),
      };
    });
    console.log("Mapped Data: ", mappedData);
    return mappedData;
  }, [eventData]);

  const table = useMantineReactTable({
    columns,
    data: data ?? [],
    initialState: {
      density: "xs",
      grouping: ["category"],
      showColumnFilters: true,
    },
    enableExpanding: true,
    enableGrouping: true,
    filterFromLeafRows: true,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="lightblue"
          onClick={handleExportData}
          leftIcon={<IconDownload />}
          variant="filled"
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  return <MantineReactTable table={table} />;
};
